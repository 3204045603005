<template>
  <div class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom">
        <VocabularyTitle :title="$t('sideBar.classes')" :per="paginationData.per_page" :entries="paginationData.total" />
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />
        <!--Search-->
        <div class="d-flex justify-content-between mt-3 w-100">
          <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />
          <search-input v-model="searchElements" @searchTable="pageClick(1)" />
        </div>
        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="vocabulary-table">
        <div class="table-header sticky-vocabulary-header">
          <div class="table-header-text justify-content-start" style="flex-basis: 100%">
            <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
            {{ $t('sideBar.classes') }}
          </div>
        </div>

        <SpinnerLoader :loading="classesStatus" />

        <DropDownTable
          v-for="row in tableData"
          :key="row.id"
          :row="row"
          :child-item="row.brand_groups"
          edit-permission="brand_class.update"
          add-permission="brand_group.create"
          delete-permission="brand_class.delete"
          edit-inner-permission="brand_group.update"
          delete-inner-permission="brand_group.delete"
          @showModalAddChild="showModalAddGroup"
          @showModalEditChild="showModalEditGroup"
          @showModalDeleteChild="showModalDeleteGroup"
          @showModalEditParent="showModalClasses"
          @showModalDeleteParent="showModalDeleteClasses"
        />

        <div v-if="!tableData.length && classesStatus === 'success'" class="mt-2 d-flex justify-content-center">{{ $t('table.noContent') }}</div>
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <b-button v-if="$checkPermissions('brand_class.create')" variant="primary" @click="showModalClasses()">
          {{ $t('table.addNewClasses') }}
        </b-button>
      </div>
      <!--FOOTER-->
    </div>

    <!--MODAL ADD/EDIT CLASS-->
    <b-modal
      id="add-edit-classes"
      ref="add-edit-classes"
      size="xl"
      :title="modalClass.type === 'add' ? $t('table.addNewClasses') : $t('table.editClasses')"
      :ok-disabled="$v.modalClass.$invalid"
      :ok-title="modalClass.type === 'add' ? $t('table.add') : $t('table.edit')"
      :cancel-title="$t('table.cancel')"
      :busy="isModalBusy"
      @ok.prevent="checkIfValidClassThenEnter"
    >
      <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidClassThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <b-form-group :label="$t('table.writeTitle')" label-for="input-class-name">
          <b-form-input id="input-class-name" v-model.trim="modalClass.name" :placeholder="$t('table.enterTitle')" autofocus required></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('table.selectBrandGroup')">
          <CoupleSelects
            :add-element.sync="modalClass.selectAdd"
            :delete-element.sync="modalClass.selectDelete"
            :add-options="modalClass.options"
            :remove-options="modalClass.value"
            @leftHandler="leftHandlerAdd()"
            @rightHandler="rightHandlerAdd()"
          />
        </b-form-group>
      </form>
    </b-modal>
    <!--MODAL ADD/EDIT CLASS-->

    <!--MODAL ADD/EDIT GROUP-->
    <b-modal
      ref="add-edit-group"
      :title="modalGroup.type === 'add' ? $t('table.addGroup') : $t('table.editGroup')"
      :ok-disabled="$v.modalGroup.$invalid"
      :ok-title="modalGroup.type === 'add' ? $t('table.add') : $t('table.edit')"
      :cancel-title="$t('table.cancel')"
      :busy="isModalBusy"
      @ok.prevent="checkIfValidGroupThenEnter"
    >
      <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidGroupThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <b-form-group :label="$t('table.writeTitle')" label-for="input-group-name">
          <b-form-input id="input-group-name" v-model.trim="modalGroup.name" :placeholder="$t('table.enterTitle')" autofocus required></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
    <!--MODAL ADD/EDIT GROUP-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import DropDownTable from '../components/DropDownTable';
import errorsHandler from '@/utils/errorsHandler';
import PaginateWrapper from '../components/PaginateWrapper';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import SortArrows from '../components/SortArrows.vue';
import CoupleSelects from '@/components/CoupleSelects.vue';

export default {
  name: 'Classes',
  components: { PaginateWrapper, DropDownTable, SpinnerLoader, VocabularyTitle, SearchDropdown, SearchInput, SortArrows, CoupleSelects },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
  },

  data() {
    return {
      tableData: [],
      paginationData: '',
      searchElements: '',

      filterPage: 1,
      selectedPerPage: '100',

      modalClass: {
        selectAdd: '',
        selectDelete: '',
        type: 'add',
        name: '',
        value: [],
        options: [],
      },
      modalGroup: {
        type: 'add',
        name: '',
        brand_class: '',
      },
      modalAddGroupClassId: '',
      modalEditGroup: '',
      isModalBusy: false,
      isModalLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      classes: 'getClasses',
      classesStatus: 'getClassesStatus',
      group: 'getGroupList',
      modalEditClasses: 'getModalEditClasses',
    }),
  },
  validations: {
    modalClass: {
      name: { required },
    },
    modalGroup: {
      name: { required },
    },
  },
  watch: {
    classes() {
      this.setTableData();
    },
  },
  created() {
    document.title = this.$i18n.t('sideBar.classes') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getClassesInfo();
  },
  destroyed() {
    this.$store.commit('clearClassesList');
    this.$store.commit('clearGroups');
  },
  methods: {
    leftHandlerAdd() {
      if (this.modalClass.selectAdd.id) {
        this.modalClass.options = this.modalClass.options.filter((option) => option.id !== this.modalClass.selectAdd.id);
        this.modalClass.value.unshift(this.modalClass.selectAdd);
        this.modalClass.selectAdd = '';
      }
    },
    rightHandlerAdd() {
      if (this.modalClass.selectDelete.id) {
        this.modalClass.value = this.modalClass.value.filter((option) => option.id !== this.modalClass.selectDelete.id);
        this.modalClass.options.unshift(this.modalClass.selectDelete);
        this.modalClass.selectDelete = '';
      }
    },

    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_CLASSES', {
        page: +this.filterPage,
        per_page: +this.selectedPerPage,
        'filter[name]': this.searchElements !== '' ? this.searchElements : null,
        sort: this.sortString ? this.sortString : null,
        include: 'brandGroups',
      });
      this.updateQuery();
    },

    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },

    // get table info
    setTableData() {
      if (this.classes) {
        this.tableData = this.classes.data;
        this.paginationData = this.classes.pagination;
        if (this.filterPage > this.classes.pagination.last_page) {
          this.filterPage = this.classes.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },
    async getClassesInfo() {
      await this.restoreFromQuery();
      this.paramsData();
    },

    clearClassFields() {
      this.modalClass = {
        selectAdd: '',
        selectDelete: '',
        type: 'add',
        name: '',
        value: [],
        options: [],
      };
    },

    clearGroupFields() {
      this.modalGroup = {
        type: 'add',
        name: '',
        brand_class: '',
      };
      this.modalAddGroupClassId = '';
      this.modalAddGroup = '';
    },

    // modal add new
    async showModalClasses(data) {
      this.isModalLoading = true;
      this.clearClassFields();
      data ? (this.modalClass.type = 'edit') : (this.modalClass.type = 'add');
      this.$refs['add-edit-classes'].show();
      await this.$store.dispatch('GET_GROUP', {});
      this.modalClass.options = this.group;

      if (data) {
        await this.$store.dispatch('GET_CLASSES_ID', data.data);
        if (this.modalEditClasses) {
          this.modalClass.name = this.modalEditClasses.name;
          this.modalClass.value = this.modalEditClasses.brand_groups || [];
          const ids = this.modalClass.value.map((el) => el.id);
          this.modalClass.options = this.modalClass.options.filter((el) => !ids.includes(el.id));
        }
      }
      this.isModalLoading = false;
    },

    async addEditClasses() {
      this.isModalBusy = true;
      const formData = {
        id: this.modalClass.type === 'add' ? undefined : this.modalEditClasses.id,
        name: this.modalClass.name,
        brand_groups: this.modalClass.value.map((v) => v.id),
      };
      await this.$store.dispatch(this.modalClass.type === 'add' ? 'POST_CLASSES' : 'PUT_CLASSES_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.modalClass.type === 'add' ? this.$i18n.t('alert.addClasses') : this.$i18n.t('alert.editClasses'),
            text: this.modalClass.name,
          });
          this.$refs['add-edit-classes'].hide();
          this.paramsData();
          this.clearClassFields();
        },
        handlerError: (errors) => errorsHandler(errors, this.$notify),
      });
      this.isModalBusy = false;
    },

    // modal delete
    async showModalDeleteClasses(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.confirmDeleteClasses')} "${data?.data?.name}"?`, {
          title: this.$i18n.t('table.deleteClasses'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-class-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data?.data?.id;
            const name = data?.data?.name;
            this.$store.dispatch('DELETE_CLASSES_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.deleteBrand'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => errorsHandler(errors, this.$notify),
            });
          }
        })
        .catch((err) => {});
    },

    // modal add group
    async showModalAddGroup(data) {
      this.clearGroupFields();
      this.modalGroup.type = 'add';
      this.modalAddGroupClassId = data?.id;
      this.$refs['add-edit-group'].show();
    },

    // modal edit Group
    async showModalEditGroup(data) {
      this.clearGroupFields();
      this.modalGroup.type = 'edit';
      this.modalAddGroupClassId = data.brand_class_id;
      this.modalEditGroup = data;
      this.modalGroup.name = data.name;
      this.$refs['add-edit-group'].show();
    },

    async addEditGroups() {
      this.isModalBusy = true;
      const formData = {
        brand_class_id: this.modalAddGroupClassId,
        id: this.modalGroup.type === 'add' ? undefined : this.modalEditGroup.id,
        name: this.modalGroup.name,
      };
      await this.$store.dispatch(this.modalGroup.type === 'add' ? 'POST_GROUP' : 'PUT_GROUP_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.modalGroup.type === 'add' ? this.$i18n.t('alert.addGroup') : this.$i18n.t('alert.editGroup'),
            text: this.modalGroup.name,
          });
          this.$refs['add-edit-group'].hide();
          this.paramsData();
          this.clearGroupFields();
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isModalBusy = false;
    },

    // modal delete Group
    async showModalDeleteGroup(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.confirmDeleteGroup')} "${data?.name}"?`, {
          title: this.$i18n.t('table.deleteGroup'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-group-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data?.id;
            const name = data?.name;
            this.$store.dispatch('DELETE_GROUP_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.deleteGroup'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => errorsHandler(errors, this.$notify),
            });
          }
        })
        .catch((err) => {});
    },

    checkIfValidClassThenEnter() {
      if (!this.$v.modalClass.$invalid && !this.isModalBusy) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalClass.type === 'add'
              ? `${this.$i18n.t('table.confirmAddClasses')} "${this.modalClass.name}"?`
              : `${this.$i18n.t('table.confirmEditClasses')} "${this.modalClass.name}"?`,
            {
              title: this.modalClass.type === 'add' ? this.$i18n.t('table.addNewClasses') : this.$i18n.t('table.editClasses'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-class-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditClasses();
          })
          .catch((err) => {});
      }
    },

    checkIfValidGroupThenEnter() {
      if (!this.$v.modalGroup.$invalid && !this.isModalBusy) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalGroup.type === 'add'
              ? `${this.$i18n.t('table.confirmAddGroup')} "${this.modalGroup.name}"?`
              : `${this.$i18n.t('table.confirmEditGroup')} "${this.modalGroup.name}"?`,
            {
              title: this.modalGroup.type === 'add' ? this.$i18n.t('table.addGroup') : this.$i18n.t('table.editGroup'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-class-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditGroups();
          })
          .catch((err) => {});
      }
    },
  },
};
</script>

<style lang="scss"></style>
